import React from 'react';
import './SettingsView.scss';

import rituals from 'assets/images/rituals.jpg';
import { Card, Button, Title, Input, Table, Text, Tabs, TabsList, Tab, TabContent } from 'components/ui-components';
import { useSettingsViewVM } from './useSettingsViewVM';

export const SettingsView: React.FC = () => {
    const {
        updatableUser,
        handleUpdatableUserChange,
        accountInfosForm,
        companyInfosForm,
        resetPasswordForm,
        invoices,
        invoicesTableColumns,
        tabs
    } = useSettingsViewVM();

    console.log('updatableUser', updatableUser);
    console.log('handleUpdatableUserChange', handleUpdatableUserChange);

    return (
        <div className="settings">
            <Tabs>
                <TabsList tabs={tabs} />
                <TabContent name="compte" active>
                    <div id="compte" className="settings-view">
                        <div className="settings-section-profile-picture">
                            <div className="settings-section-title">
                                <Title variant="h2">Photo de profil</Title>
                            </div>
                            <Card>
                                <img alt="User profile picture" src={rituals} className="user-profile-picture" onClick={() => {}} />
                                <Button hasBorder={true} type="submit">
                                    Modifier ma photo
                                </Button>
                            </Card>
                        </div>
                        <div className="settings-section-account-and-reset-password">
                            <div className="settings-section-account-infos">
                                <div className="settings-section-title">
                                    <Title variant="h2">Informations du compte</Title>
                                </div>
                                <Card>
                                    <form onSubmit={accountInfosForm.handleSubmit}>
                                        <Input
                                            id="firstname"
                                            name="firstname"
                                            type="text"
                                            label="Prénom :"
                                            placeholder="Prénom"
                                            onValueChange={() => {}}
                                            onChange={accountInfosForm.handleChange}
                                            value={accountInfosForm.values.firstname}
                                            greyed
                                        />
                                        <Input
                                            id="lastname"
                                            name="lastname"
                                            type="text"
                                            label="Nom :"
                                            onValueChange={() => {}}
                                            onChange={accountInfosForm.handleChange}
                                            value={accountInfosForm.values.lastname}
                                            greyed
                                        />
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            label="E-mail :"
                                            onValueChange={() => {}}
                                            onChange={accountInfosForm.handleChange}
                                            value={accountInfosForm.values.email}
                                            greyed
                                        />
                                        <Input
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            label="Téléphone :"
                                            onValueChange={() => {}}
                                            onChange={accountInfosForm.handleChange}
                                            value={accountInfosForm.values.phone}
                                            greyed
                                        />
                                        <Button hasBorder={true} type="submit">
                                            Modifier mes informations
                                        </Button>
                                    </form>
                                </Card>
                            </div>
                            <div className="settings-section-reset-password">
                                <div className="settings-section-title">
                                    <Title variant="h2">Gestion de mot de passe</Title>
                                </div>
                                <Card>
                                    <form onSubmit={resetPasswordForm.handleSubmit}>
                                        <Input
                                            id="oldPassword"
                                            name="oldPassword"
                                            label="Mot de passe actuel :"
                                            onValueChange={() => {}}
                                            onChange={resetPasswordForm.handleChange}
                                            value={resetPasswordForm.values.oldPassword}
                                            isPassword={true}
                                            greyed
                                        />
                                        <Input
                                            id="newPassword"
                                            name="newPassword"
                                            label="Nouveau mot de passe :"
                                            onValueChange={() => {}}
                                            onChange={resetPasswordForm.handleChange}
                                            value={resetPasswordForm.values.newPassword}
                                            isPassword={true}
                                            greyed
                                        />
                                        <Input
                                            id="newPasswordConfirmation"
                                            name="newPasswordConfirmation"
                                            label="Confirmer le mot de passe :"
                                            onValueChange={() => {}}
                                            onChange={resetPasswordForm.handleChange}
                                            value={resetPasswordForm.values.newPasswordConfirmation}
                                            isPassword={true}
                                            greyed
                                        />
                                        <Button hasBorder={true} type="submit">
                                            Modifier mon mot de passe
                                        </Button>
                                    </form>
                                </Card>
                            </div>
                        </div>
                        <div className="settings-section-company-infos">
                            <div className="settings-section-title">
                                <Title variant="h2">Informations de société</Title>
                            </div>
                            <Card>
                                <form onSubmit={companyInfosForm.handleSubmit}>
                                    <Input
                                        id="address"
                                        name="address"
                                        type="text"
                                        label="Adresse :"
                                        onValueChange={() => {}}
                                        onChange={companyInfosForm.handleChange}
                                        value={companyInfosForm.values.address}
                                        greyed
                                    />
                                    <Input
                                        id="name"
                                        name="name"
                                        type="text"
                                        label="Nom :"
                                        onValueChange={() => {}}
                                        onChange={companyInfosForm.handleChange}
                                        value={companyInfosForm.values.name}
                                        greyed
                                    />
                                    <Input
                                        id="siret"
                                        name="siret"
                                        type="text"
                                        label="Numéro de SIRET :"
                                        onValueChange={() => {}}
                                        onChange={companyInfosForm.handleChange}
                                        value={companyInfosForm.values.siret}
                                        greyed
                                    />
                                    <Input
                                        id="status"
                                        name="status"
                                        type="string"
                                        label="Type de société :"
                                        onValueChange={() => {}}
                                        onChange={companyInfosForm.handleChange}
                                        value={companyInfosForm.values.status}
                                        greyed
                                    />
                                    <Input
                                        id="vat"
                                        name="vat"
                                        type="text"
                                        label="Numéro de TVA :"
                                        onValueChange={() => {}}
                                        onChange={companyInfosForm.handleChange}
                                        value={companyInfosForm.values.vat}
                                        greyed
                                    />
                                    <Input
                                        id="website"
                                        name="website"
                                        type="text"
                                        label="Site web :"
                                        onValueChange={() => {}}
                                        onChange={companyInfosForm.handleChange}
                                        value={companyInfosForm.values.website}
                                        greyed
                                    />
                                    <Button hasBorder={true} type="submit">
                                        Modifier les informations de ma société
                                    </Button>
                                </form>
                            </Card>
                        </div>
                    </div>
                </TabContent>
                <TabContent name="abonnement">
                    <div id="abonnement" className="settings-view">
                        <div className="settings-section-subscriptions">
                            <div className="settings-section-title">
                                <Title variant="h2">Abonnement</Title>
                            </div>
                            <Card>
                                <Title variant="h3">Canapp Starter</Title>
                                {/* <Text variant="h3"></Title> */}
                                <Button hasBorder={true} type="submit">
                                    Changer d'abonnement
                                </Button>
                            </Card>
                        </div>
                        <div className="settings-section-invoices">
                            <div className="settings-section-title">
                                <Title variant="h2">Vos factures</Title>
                            </div>
                            <Card>
                                <Table columns={invoicesTableColumns} rows={invoices} quickFilterText={''} />
                            </Card>
                        </div>
                    </div>
                </TabContent>
            </Tabs>
        </div>
    );
};
