import { canappApi } from 'services/providers/canapp';
import { ApiEmptyRequest, ApiRequest, ApiResponse } from 'services/providers/canapp.types';
import {
    AlbumCreatePayload,
    AlbumDeletePayload,
    AlbumInformations,
    AlbumUpdatePayload,
    AlbumsInformations,
    GetOneAlbumByIdPayload
} from './album.types';

const albumApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        getAllAlbums: build.query<ApiResponse<AlbumsInformations>, ApiEmptyRequest>({
            query: () => ({
                url: '/app/albums',
                method: 'GET',
                body: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        getAlbumById: build.query<ApiResponse<AlbumInformations>, ApiRequest<GetOneAlbumByIdPayload>>({
            query: ({ albumId }) => ({
                url: `/app/album/${albumId}`,
                method: 'GET',
                params: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        updateAlbum: build.mutation<ApiResponse<AlbumInformations>, ApiRequest<AlbumUpdatePayload>>({
            query: ({ albumId, label }) => ({
                url: `/app/album/${albumId}`,
                method: 'POST',
                body: {
                    label
                }
            }),
            extraOptions: { maxRetries: 3 }
        }),
        createAlbum: build.mutation<ApiResponse<AlbumInformations>, ApiRequest<AlbumCreatePayload>>({
            query: (body) => ({
                url: `/app/album`,
                method: 'POST',
                body
            }),
            extraOptions: { maxRetries: 3 }
        }),
        deleteAlbum: build.mutation<ApiResponse<undefined>, ApiRequest<AlbumDeletePayload>>({
            query: ({ albumId }) => ({
                url: `/app/album/${albumId}`,
                method: 'DELETE'
            }),
            extraOptions: { maxRetries: 3 }
        })
    })
});

export const {
    useCreateAlbumMutation,
    useDeleteAlbumMutation,
    useLazyGetAllAlbumsQuery,
    useLazyGetAlbumByIdQuery,
    useUpdateAlbumMutation
} = albumApi;
