import { useEffect, useState } from 'react';
import { useLazyGetAlbumByIdQuery, useLazyGetAllAlbumsQuery } from 'services/album/album.endpoint';
import { useLazyGetAllMediasQuery } from 'services/album/media/media.endpoint';

export const useLibraryViewVM = () => {
    const [search, setSearch] = useState<string>('');
    const [allAlbums, { data: albums }] = useLazyGetAllAlbumsQuery(undefined);
    const [allMedias, mediasByAlbum] = useLazyGetAllMediasQuery(undefined);
    const [albumsPreviews, setAlbumsPreviews] = useState<string[]>();

    useEffect(() => {
        if (!allAlbums) return;
        allAlbums(undefined, false);
    }, [allAlbums]);

    useEffect(() => {
        const fetchPreviews = async () => {
            if (!albums) return;

            const previews = await Promise.all(
                albums.map(async ({ id }) => {
                    const { data: medias } = await allMedias({ albumId: id });
                    return medias && medias.length ? medias[medias.length - 1].file.view_link : '';
                })
            );

            setAlbumsPreviews(previews);
        };

        fetchPreviews();
    }, [albums]);

    return {
        albums,
        search,
        setSearch,
        albumsPreviews
    };
};
