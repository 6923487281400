import React from 'react';
import { Notification } from 'services/notification/notification.types';
import { Text } from 'components/ui-components';
import { formatDateCondensed } from 'utils/dates';

import { IoIosChatbubbles } from 'react-icons/io';
import { MdOutlineSupportAgent, MdScreenShare } from 'react-icons/md';
import { BiSolidStore } from 'react-icons/bi';
import { LuCheck } from 'react-icons/lu';

export const MapSubjectToInfos = {
    message: {
        icon: <IoIosChatbubbles />,
        title: "L'équipe Canapp"
    },
    ticket: {
        icon: <MdOutlineSupportAgent />,
        title: 'Support'
    },
    device: {
        icon: <MdScreenShare />,
        title: 'Appareillage'
    },
    connector: {
        icon: <BiSolidStore />,
        title: 'Connecteurs'
    }
};

export const notificationProvider = (notification: Notification): React.ReactNode => {
    const { icon, title } = MapSubjectToInfos[notification.subject];

    return (
        <>
            <div className="inner-notification">
                <div className="notification-subject">
                    {icon}
                    <Text fontWeight={500} className="notification-title">
                        {title}
                    </Text>
                </div>
                <Text greyed className="notification-date" type="medium">
                    {formatDateCondensed(notification.created_at)}
                </Text>
            </div>
            <Text type="medium" fontWeight={500} greyed>
                {notification.data ? notification.data : notification.action}
            </Text>
            {notification.viewed && (
                <Text className="viewed-text" greyed fontWeight={500} type="small">
                    <LuCheck /> Lu
                </Text>
            )}
        </>
    );
};
