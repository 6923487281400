import { canappApi } from 'services/providers/canapp';
import { ApiEmptyRequest, ApiRequest, ApiResponse } from 'services/providers/canapp.types';
import { NotificationsInformations, NotificationViewPayload } from './notification.types';

const notificationApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        getAllNotifications: build.query<ApiResponse<NotificationsInformations>, ApiEmptyRequest>({
            query: () => ({
                url: '/app/notifications',
                method: 'GET',
                body: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        viewNotification: build.mutation<ApiResponse<undefined>, ApiRequest<NotificationViewPayload>>({
            query: ({ notificationId }) => ({
                url: `/app/notification/${notificationId}`,
                method: 'PUT'
            }),
            extraOptions: { maxRetries: 3 }
        })
    })
});

export const { useLazyGetAllNotificationsQuery, useViewNotificationMutation } = notificationApi;
