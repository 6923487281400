import React from 'react';
import './Tabs.scss';
import { getCombinedClassName } from 'utils/components';

export type TabContentProps = {
    name: string;
    active?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

export const TabContent: React.FC<TabContentProps> = ({ children, active, className, name, ...props }) => {
    const combinedClassName = getCombinedClassName(`tab-content ${name} ${active ? 'active' : ''}`, className);

    return (
        <div className={combinedClassName} {...props}>
            {children}
        </div>
    );
};
