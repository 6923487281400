import React from 'react';
import './Notifications.scss';
import { useNotifications } from './useNotifications';
import { Text, Title } from 'components/ui-components';
import { notificationProvider } from './notificationProvider';
import { Notification } from 'services/notification/notification.types';

export type NotificationsProps = {
    setNbNotifications: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const Notifications: React.FC<NotificationsProps> = ({ setNbNotifications }) => {
    const { notifications, handleViewNotification } = useNotifications({ setNbNotifications });

    return (
        <div className="notifications">
            <Title variant="h3">Mes notifications</Title>
            {notifications && notifications.length ? (
                <div className="notifications-list">
                    {notifications.map((notification: Notification, i: number) => (
                        <div
                            className={`notification ${notification.viewed ? 'viewed' : ''}`}
                            onClick={() => handleViewNotification(notification.id)}
                            key={`notification-${i}`}
                        >
                            {notificationProvider(notification)}
                        </div>
                    ))}
                </div>
            ) : (
                <Text greyed>Il ne se passe pas grand chose ici...</Text>
            )}
        </div>
    );
};
