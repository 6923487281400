import { useEffect, useState } from 'react';
import { useLazyGetAllNotificationsQuery, useViewNotificationMutation } from 'services/notification/notification.endpoints';
import { extractErrorMessage } from 'utils/errors';
import { useNavigate } from 'react-router-dom';

export const useNotificationsViewVM = () => {
    const navigate = useNavigate();
    const [notificationsError, setNotificationsError] = useState<string>();
    const [search, setSearch] = useState<string>();
    const [opened, setOpened] = useState<number | null>(null);

    const [allNotifications, notificationsResult] = useLazyGetAllNotificationsQuery(undefined);

    useEffect(() => {
        if (!allNotifications) return;
        allNotifications(undefined, false);
    }, [allNotifications]);

    const [
        viewNotification,
        {
            isLoading: isViewNotificationLoading,
            isError: isViewNotificationError,
            isSuccess: isViewNotificationSuccess,
            error: viewNotificationError,
            data: viewNotificationData
        }
    ] = useViewNotificationMutation();

    useEffect(() => {
        if (isViewNotificationLoading) return;
        if (isViewNotificationError && viewNotificationError) {
            setNotificationsError(extractErrorMessage(viewNotificationError));
        }
        if (isViewNotificationSuccess && viewNotificationData) {
            allNotifications(undefined, false);
            return;
        }
    }, [
        isViewNotificationLoading,
        isViewNotificationError,
        isViewNotificationSuccess,
        viewNotificationError,
        viewNotificationData,
        allNotifications
    ]);

    const handleViewNotification = (notificationId: number) => {
        viewNotification({ notificationId });
    };

    const toggleOpened = (id: number) => {
        setOpened((prevOpened) => (prevOpened === id ? null : id));
    };

    const navigateToDetails = (subject: string, subject_id: number | undefined) => {
        switch (subject) {
            case 'ticket':
                navigate(`/dashboard/support/messages/${subject_id}`);
                break;
            case 'device':
                navigate(`/dashboard/screens/${subject_id}`);
                break;
            case 'connector':
                navigate(`/dashboard/connectors/${subject_id}`);
                break;
            default:
                navigate(`#`);
        }
    };

    const displayButtonText = (subject: string) => {
        switch (subject) {
            case 'ticket':
                return 'Aller au ticket';
            case 'device':
                return 'Aller à l\'écran';
            case 'connector':
                return 'Aller au connecteur';
            default:
                return 'Y aller';
        }
    };

    return {
        notifications: notificationsResult.data,
        notificationsError,
        handleViewNotification,
        search,
        setSearch,
        opened,
        setOpened,
        toggleOpened,
        navigateToDetails,
        displayButtonText
    };
};
