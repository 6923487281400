import { useEffect, useState } from 'react';
import { useLazyGetAllNotificationsQuery, useViewNotificationMutation } from 'services/notification/notification.endpoints';
import { extractErrorMessage } from 'utils/errors';
import { NotificationsProps } from './Notifications';

export const useNotifications = ({ setNbNotifications }: NotificationsProps) => {
    const [notificationsError, setNotificationsError] = useState<string>();

    const [allNotifications, notificationsResult] = useLazyGetAllNotificationsQuery(undefined);

    useEffect(() => {
        setNbNotifications(0);
    }, []);

    useEffect(() => {
        if (!allNotifications) return;
        allNotifications(undefined, false);
    }, [allNotifications]);

    const [
        viewNotification,
        {
            isLoading: isViewNotificationLoading,
            isError: isViewNotificationError,
            isSuccess: isViewNotificationSuccess,
            error: viewNotificationError,
            data: viewNotificationData
        }
    ] = useViewNotificationMutation();

    useEffect(() => {
        if (isViewNotificationLoading) return;
        if (isViewNotificationError && viewNotificationError) {
            setNotificationsError(extractErrorMessage(viewNotificationError));
        }
        if (isViewNotificationSuccess && viewNotificationData) {
            allNotifications(undefined, false);
            return;
        }
    }, [
        isViewNotificationLoading,
        isViewNotificationError,
        isViewNotificationSuccess,
        viewNotificationError,
        viewNotificationData,
        allNotifications
    ]);

    const handleViewNotification = (notificationId: number) => {
        viewNotification({ notificationId });
    };

    return {
        notifications: notificationsResult.data,
        notificationsError,
        handleViewNotification
    };
};
