import React from 'react';
import { BiSolidCarousel } from 'react-icons/bi';
import { FaBookBookmark } from 'react-icons/fa6';
import { MdOutlineSupportAgent, MdScreenShare } from 'react-icons/md';
import { BiSolidStore } from 'react-icons/bi';
import { PiHouseFill } from 'react-icons/pi';
import { FaGear } from 'react-icons/fa6';

export type DashboardItem = {
    title: string;
    icon: React.ReactNode;
    url: string;
};

export const items: DashboardItem[] = [
    {
        title: 'Tableau de bord',
        icon: <PiHouseFill />,
        url: '/dashboard'
    },
    {
        title: 'Appareiller',
        icon: <MdScreenShare />,
        url: '/dashboard/screens'
    },
    {
        title: 'Connecteurs',
        icon: <BiSolidStore />,
        url: '/dashboard/connectors'
    },
    {
        title: 'Librairie',
        icon: <FaBookBookmark />,
        url: '/dashboard/library'
    },
    {
        title: 'Slider',
        icon: <BiSolidCarousel />,
        url: '/dashboard/slider'
    },
    // {
    //     title: 'Plannification',
    //     icon: <PiProjectorScreenChartFill />,
    //     url: '/dashboard/planning'
    // },
    // {
    //     title: 'Analyse',
    //     icon: <FaChartLine />,
    //     url: '/dashboard/analysis'
    // },
    {
        title: 'Paramètres',
        icon: <FaGear />,
        url: '/dashboard/settings'
    },
    {
        title: 'Support',
        icon: <MdOutlineSupportAgent />,
        url: '/dashboard/support'
    }
];
