import { canappApi } from 'services/providers/canapp';
import { ApiEmptyRequest, ApiRequest, ApiResponse } from 'services/providers/canapp.types';
import {
    UserCreatePayload,
    UserDeletePayload,
    UserInformations,
    UserUpdatePayload,
    UsersInformations,
    GetOneUserByIdPayload
} from './user.types';

const userApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        getAllUsers: build.query<ApiResponse<UsersInformations>, ApiEmptyRequest>({
            query: () => ({
                url: '/app/users',
                method: 'GET',
                body: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        getUserById: build.query<ApiResponse<UserInformations>, ApiRequest<GetOneUserByIdPayload>>({
            query: ({ userId }) => ({
                url: `/app/user/${userId}`,
                method: 'GET',
                params: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        getMe: build.query<ApiResponse<UserInformations>, ApiRequest<any>>({
            query: () => ({
                url: `/app/user/me`,
                method: 'GET',
                params: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        updateUser: build.mutation<ApiResponse<UserInformations>, ApiRequest<UserUpdatePayload>>({
            query: ({ userId, ...body }) => ({
                url: `/app/user/${userId}`,
                method: 'POST',
                body
            }),
            extraOptions: { maxRetries: 3 }
        }),
        createUser: build.mutation<ApiResponse<UserInformations>, ApiRequest<UserCreatePayload>>({
            query: (body) => ({
                url: `/app/user`,
                method: 'POST',
                body
            }),
            extraOptions: { maxRetries: 3 }
        }),
        deleteUser: build.mutation<ApiResponse<undefined>, ApiRequest<UserDeletePayload>>({
            query: ({ userId }) => ({
                url: `/app/user/${userId}`,
                method: 'DELETE'
            }),
            extraOptions: { maxRetries: 3 }
        })
    })
});

export const { useCreateUserMutation, useGetAllUsersQuery, useGetUserByIdQuery, useDeleteUserMutation, useLazyGetMeQuery } = userApi;
