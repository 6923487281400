import React from 'react';
import './Tabs.scss';
import { getCombinedClassName } from 'utils/components';

export type TabsProps = {} & React.ComponentPropsWithoutRef<'div'>;

export const Tabs: React.FC<TabsProps> = ({ children, className, ...props }) => {
    const combinedClassName = getCombinedClassName(`tabs `, className);

    return (
        <div className={combinedClassName} {...props}>
            {children}
        </div>
    );
};
