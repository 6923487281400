import { canappApi } from 'services/providers/canapp';
import { ApiEmptyRequest, ApiRequest, ApiResponse } from 'services/providers/canapp.types';
import {
    MediaCreatePayload,
    MediaDeletePayload,
    MediaInformations,
    MediaUpdatePayload,
    MediasInformations,
    GetOneMediaByIdPayload,
    GetAllMediasByIdPayload
} from './media.types';

const mediaApi = canappApi.injectEndpoints({
    endpoints: (build) => ({
        getAllMedias: build.query<ApiResponse<MediasInformations>, ApiRequest<GetAllMediasByIdPayload>>({
            query: ({ albumId }) => ({
                url: `/app/album/${albumId}/medias`,
                method: 'GET',
                body: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        getMediaById: build.query<ApiResponse<MediaInformations>, ApiRequest<GetOneMediaByIdPayload>>({
            query: ({ mediaId, albumId }) => ({
                url: `/app/album/${albumId}/media/${mediaId}`,
                method: 'GET',
                params: undefined
            }),
            extraOptions: { maxRetries: 3 }
        }),
        updateMedia: build.mutation<ApiResponse<MediaInformations>, ApiRequest<MediaUpdatePayload>>({
            query: ({ mediaId, title, description, albumId }) => ({
                url: `/app/album/${albumId}/media/${mediaId}`,
                method: 'POST',
                body: {
                    title,
                    description
                }
            }),
            extraOptions: { maxRetries: 3 }
        }),
        createMedia: build.mutation<ApiResponse<MediaInformations>, ApiRequest<MediaCreatePayload>>({
            query: ({ albumId, ...body }) => ({
                url: `/app/album/${albumId}/media`,
                method: 'POST',
                body
            }),
            extraOptions: { maxRetries: 3 }
        }),
        deleteMedia: build.mutation<ApiResponse<undefined>, ApiRequest<MediaDeletePayload>>({
            query: ({ mediaId, albumId }) => ({
                url: `/app/album/${albumId}/media/${mediaId}`,
                method: 'DELETE'
            }),
            extraOptions: { maxRetries: 3 }
        })
    })
});

export const {
    useCreateMediaMutation,
    useDeleteMediaMutation,
    useLazyGetAllMediasQuery,
    useLazyGetMediaByIdQuery,
    useUpdateMediaMutation
} = mediaApi;
