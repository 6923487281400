import { Button, Input, OutsideAlerter, Text, Title } from 'components/ui-components';
import React from 'react';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { IoMdSettings } from 'react-icons/io';
import { FaBell } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import wavingHand from 'assets/images/waving-hand.png';
import rituals from 'assets/images/rituals.jpg';
import './Header.scss';
import { useHeaderVm } from './useHeaderVm';
import { Link } from 'components/ui-components/Link/Link';
import { SearchResults } from '..';
import { Pin } from 'components/ui-components/Pin/Pin';
import { Notifications } from 'components/Notifications/Notifications';

export type HeaderProps = {
    title: string;
    nbNotifications: number | undefined;
    setNbNotifications: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const Header: React.FC<HeaderProps> = ({ title, nbNotifications, setNbNotifications }) => {
    const {
        isLogged,
        handleLogout,
        search,
        setSearch,
        debouncedSearch,
        displayMenu,
        setDisplayMenu,
        displaySearch,
        setDisplaySearch,
        goToSettings,
        displayNotifications,
        setDisplayNotifications,
        goToNotificationsCenter
    } = useHeaderVm();

    return (
        <div className="header">
            <Title variant="h1">{title}</Title>

            {isLogged && (
                <div className="actions-wrapper">
                    <Input value={search} onValueChange={setSearch} placeholder="Rechercher" icon={<PiMagnifyingGlassBold />} />
                    {debouncedSearch && displaySearch && (
                        <OutsideAlerter callback={() => setDisplaySearch(false)} className="search-results-wrapper">
                            <SearchResults search={debouncedSearch} setDisplaySearch={setDisplaySearch} />
                        </OutsideAlerter>
                    )}

                    <Button onlyIcon icon={<IoMdSettings />} hasBorder={false} onClick={goToSettings} />

                    <div className="notifications-icon" onClick={() => !displayNotifications && setDisplayNotifications(true)}>
                        <Button onlyIcon icon={<FaBell />} hasBorder={false} />
                        {nbNotifications ? <Pin value={nbNotifications} /> : <></>}
                    </div>
                    {displayNotifications && (
                        <OutsideAlerter callback={() => setDisplayNotifications(false)} className="notifications-wrapper">
                            <Notifications setNbNotifications={setNbNotifications} />
                            <Link callback={goToNotificationsCenter}>Voir tout</Link>
                        </OutsideAlerter>
                    )}

                    <img alt="user" src={rituals} className="user-img" onClick={() => setDisplayMenu(true)} />
                    {displayMenu && (
                        <OutsideAlerter callback={() => setDisplayMenu(false)} className="user-menu">
                            <Text className="hello-text">
                                Bonjour <b>Rituals Var</b> <img className="waving" alt="waving" src={wavingHand} />
                            </Text>
                            <div className="links">
                                <Link href="#" noUnderline>
                                    Mon compte
                                </Link>
                                <Link href="#" noUnderline>
                                    Mon abonnement
                                </Link>
                                <Link callback={handleLogout} className="spaced-link" noUnderline greyed icon={<MdLogout />}>
                                    Me déconnecter
                                </Link>
                            </div>
                        </OutsideAlerter>
                    )}
                </div>
            )}
        </div>
    );
};
