import React from 'react';
import './PaginatedContent.scss';
import { usePaginatedContent } from './usePaginatedContent';

export type PaginatedContentProps = React.ComponentPropsWithoutRef<'div'> & {
    pageSize: number;
};

export const PaginatedContent: React.FC<PaginatedContentProps> = ({ children, pageSize, ...props }) => {
    const { paginatedContent, pageIndex, totalPageIndex, setPageIndex } = usePaginatedContent({
        children: React.Children.toArray(children),
        pageSize
    });

    console.log('paginatedContent', paginatedContent);
    console.log('pageIndex', pageIndex);
    console.log('totalPageIndex', totalPageIndex);

    return (
        <div {...props} className="paginated-content">
            <div className="content">{paginatedContent}</div>
            <div className="paginated-controls">
                <button disabled={pageIndex === 1} className="prev" onClick={() => setPageIndex((prevPageIndex) => Math.max(prevPageIndex - 1, 1))}></button>
                <span className="pages-recap">{`${pageIndex} / ${totalPageIndex}`}</span>
                <button disabled={pageIndex === totalPageIndex} className="next" onClick={() => setPageIndex((prevPageIndex) => Math.min(prevPageIndex + 1, totalPageIndex))}></button>
            </div>
        </div>
    );
};
