import AuthentifiedLayout from 'layout/AuthentifiedLayout';
import UnAuthentifiedLayout from 'layout/UnAuthentifiedLayout';

import { Navigate } from 'react-router-dom';
import { DashboardView } from 'views/dashboardView/DashboardView';
import { AnalysisView } from 'views/dashboardView/analysisView/AnalysisView';
import { ConnectorsView } from 'views/dashboardView/connectorsView/ConnectorsView';
import { DashView } from 'views/dashboardView/dashView/DashView';
import { LibraryView } from 'views/dashboardView/libraryView/LibraryView';
import { ScreensView } from 'views/dashboardView/screensView/ScreensView';
import { PlanningView } from 'views/dashboardView/planningView/PlanningView';
import { SettingsView } from 'views/dashboardView/settingsView/SettingsView';
import { SliderView } from 'views/dashboardView/sliderView/SliderView';
import { SupportView } from 'views/dashboardView/supportView/SupportView';
import { DefaultView } from 'views/defaultView/DefaultView';
import { TicketMessagesView } from 'views/dashboardView/supportView/ticketMessagesView/TicketMessagesView';
import { NotificationsView } from 'views/dashboardView/notificationsView/NotificationsView';

export const routes = (isLogged: boolean | null | undefined) => {
    return [
        {
            path: '/',
            element: <Navigate to="/loggedout" />
        },
        {
            path: '/loggedout',
            element: !isLogged ? <UnAuthentifiedLayout /> : <Navigate to="/dashboard" />,
            children: [
                {
                    path: '',
                    element: <DefaultView title="Non authentifié ❌" />
                }
            ]
        },
        {
            path: '/dashboard',
            element: isLogged ? <AuthentifiedLayout /> : <Navigate to="/loggedout" />,
            children: [
                {
                    path: '',
                    element: isLogged ? (
                        <DashboardView componentToRender={<DashView />} headerTitle="Tableau de bord" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/screens',
                    element: isLogged ? (
                        <DashboardView componentToRender={<ScreensView />} headerTitle="Appareiller" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/connectors',
                    element: isLogged ? (
                        <DashboardView componentToRender={<ConnectorsView />} headerTitle="Connecteurs" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/library',
                    element: isLogged ? (
                        <DashboardView componentToRender={<LibraryView />} headerTitle="Librairie" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/slider',
                    element: isLogged ? (
                        <DashboardView componentToRender={<SliderView />} headerTitle="Slider" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/planning',
                    element: isLogged ? (
                        <DashboardView componentToRender={<PlanningView />} headerTitle="Publication" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/analysis',
                    element: isLogged ? (
                        <DashboardView componentToRender={<AnalysisView />} headerTitle="Analyses" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/settings',
                    element: isLogged ? (
                        <DashboardView componentToRender={<SettingsView />} headerTitle="Paramètres" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/support',
                    element: isLogged ? (
                        <DashboardView componentToRender={<SupportView />} headerTitle="Support" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/support/messages/:ticketId',
                    element: isLogged ? (
                        <DashboardView componentToRender={<TicketMessagesView />} headerTitle="Support" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                },
                {
                    path: '/dashboard/notifications',
                    element: isLogged ? (
                        <DashboardView componentToRender={<NotificationsView />} headerTitle="Notifications" />
                    ) : (
                        <Navigate to="/loggedout" />
                    )
                }
            ]
        }
    ];
};

export default routes;
