import React from 'react';
import './LibraryView.scss';
import { useLibraryViewVM } from './useLibraryViewVM';
import { Album } from 'services/album/album.types';
import { Button, Card, Input, Text, Title } from 'components/ui-components';
import { FaPlus } from 'react-icons/fa6';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { LuSlidersHorizontal } from 'react-icons/lu';
import { formatDate } from 'utils/dates';

export const LibraryView: React.FC = () => {
    const { albums, search, setSearch, albumsPreviews } = useLibraryViewVM();

    console.log('albums', albums);

    return (
        <div className="library-view">
            <Title variant="h2">Mes albums</Title>

            <Card fullWidth>
                <div className="albums-actions">
                    <Button
                        icon={<FaPlus />}
                        onClick={() => {
                            console.log('coucou');
                            //setAddTV(true)
                        }}
                    >
                        Créer un album
                    </Button>
                    <div className="albums-section-card-header-right">
                        <Input value={search} onValueChange={setSearch} greyed placeholder="Rechercher" icon={<PiMagnifyingGlassBold />} />
                        <Button background="dark" icon={<LuSlidersHorizontal />} iconPosition="right">
                            Filtrer
                        </Button>
                    </div>
                </div>
                <div className="albums-card-body">
                    {albums && albums.length ? (
                        albums.map((album: Album, i: number) => (
                            <div className="album">
                                <div className="album-medias-preview">
                                    {albumsPreviews && albumsPreviews.length ? (
                                        <>
                                            <img src={albumsPreviews[i]} alt='preview' />

                                            {/* <p>{albumsPreviews[i]}</p> */}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <Text>14 médias</Text>
                                </div>
                                <Text>{album.label}</Text>
                                <Text>{formatDate(album.created_at)}</Text>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
            </Card>
        </div>
    );
};
