import React from 'react';
import './DashboardView.scss';
import { Menu } from 'components/Menu/Menu';
import { Header } from 'components/index';
import { useDashboardView } from './useDashboardView';

export type DashboardViewProps = {
    componentToRender: React.ReactNode;
    headerTitle: string;
};

export const DashboardView: React.FC<DashboardViewProps> = ({ componentToRender, headerTitle }) => {
    const { nbNotifications, setNbNotifications } = useDashboardView();

    return (
        <div className="dashboard">
            <Menu />

            <div className="dashboard-view">
                <Header nbNotifications={nbNotifications} setNbNotifications={setNbNotifications} title={headerTitle} />
                <div className="component-rendered">{componentToRender}</div>
            </div>
        </div>
    );
};
