import React, { useState } from 'react';
import './Tabs.scss';
import { Tab } from './Tab';
import { getCombinedClassName } from 'utils/components';

export type TabsListProps = {
    tabs: string[];
} & React.ComponentPropsWithoutRef<'div'>;

export const TabsList: React.FC<TabsListProps> = ({ className, tabs, ...props }) => {
    const combinedClassName = getCombinedClassName(`tabs-list `, className);
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const setTabActive = (name: string) => {
        document.querySelectorAll('.tab, .tab-content').forEach((el) => el.classList.remove('active'));
        document.querySelector(`.tab[name="${name}"]`)?.classList.add('active');
        document.querySelector(`.tab-content.${name}`)?.classList.add('active');
    };

    return (
        <div className="tabs-list-container">
            <div className={combinedClassName} {...props}>
                {tabs.map((tab) => (
                    <Tab
                        key={tab}
                        name={tab}
                        active={activeTab === tab}
                        onClick={() => {
                            setActiveTab(tab);
                            setTabActive(tab);
                        }}
                    >
                        {tab}
                    </Tab>
                ))}
            </div>
        </div>
    );
};
