import React from 'react';
import './NotificationsView.scss';
import { useNotificationsViewVM } from './useNotificationsViewVM';
import { Card, Title, Button, Input } from 'components/ui-components';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { LuSlidersHorizontal } from 'react-icons/lu';
import { Notification } from 'services/notification/notification.types';
import { notificationProvider } from 'components/Notifications/notificationProvider';
import { PaginatedContent } from 'components/ui-components/PaginatedContent/PaginatedContent';

export const NotificationsView: React.FC = () => {
    const { notifications, search, setSearch, opened, toggleOpened, navigateToDetails, displayButtonText } = useNotificationsViewVM();

    const notificationsNodes =
        notifications &&
        notifications.map((notification: Notification) => (
            <div
                key={`notification-${notification.id}`}
                className={`notification ${opened === notification.id ? 'opened' : ''}`}
                onClick={() => {
                    toggleOpened(notification.id);
                }}
            >
                {notificationProvider(notification)}
                {notification.subject && notification.subject_id && (
                    <Button
                        background="dark"
                        onClick={() => {
                            navigateToDetails(notification.subject, notification.subject_id);
                        }}
                    >
                        {displayButtonText(notification.subject)}
                    </Button>
                )}
            </div>
        ));

    return (
        <div className="notifications-center">
            <Title variant="h2">Centre de notifications</Title>
            <Card>
                <div className="notifications-section-card-header">
                    <Title variant="h3">Vos dernières notifications</Title>
                    <div className="notifications-section-card-header-right">
                        <Input value={search} onValueChange={setSearch} greyed placeholder="Rechercher" icon={<PiMagnifyingGlassBold />} />
                        <Button background="dark" icon={<LuSlidersHorizontal />} iconPosition="right">
                            Filtrer
                        </Button>
                    </div>
                </div>
                {notificationsNodes && (
                    <PaginatedContent pageSize={20} key={opened}>
                        {notificationsNodes}
                    </PaginatedContent>
                )}
            </Card>
        </div>
    );
};
