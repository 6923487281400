import React from 'react';
import './Tabs.scss';
import { getCombinedClassName } from 'utils/components';
import { Button } from 'components/ui-components/Button/Button';
export type TabProps = {
    name: string;
    onClick: () => void;
    active?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

export const Tab: React.FC<TabProps> = ({ children, name, active, className, onClick, ...props }) => {
    const combinedClassName = getCombinedClassName(`tab ${name} ${active ? 'active' : ''}`, className);
    const backgroundColor = active ? 'dark' : 'light';

    return (
        <Button 
            className={combinedClassName} 
            background={backgroundColor} 
            onClick={onClick} 
            name={name}
            hasBorder={false}
        >
            {children}
        </Button>
    );
};
