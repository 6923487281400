import { logout } from 'features/auth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetMeQuery } from 'services/user/user.endpoints';

export const useDashboardView = () => {
    const dispatch = useDispatch();

    const [nbNotifications, setNbNotifications] = useState<number | undefined>(0);

    const [getMe, getMeResults] = useLazyGetMeQuery(undefined);

    useEffect(() => {
        if (!getMe) return;
        getMe(undefined, false);
    }, [getMe]);

    useEffect(() => {
        if (!getMeResults) return;
        if (getMeResults.isLoading || getMeResults.isFetching) {
            setNbNotifications(0);
        }
        if (getMeResults.isSuccess && getMeResults.data) {
            setNbNotifications(getMeResults.data.new_notifications_count);
        }
        if (getMeResults.isError) {
            // Wrong jwt
            dispatch(logout());
        }
    }, [getMeResults, dispatch, setNbNotifications]);

    return {
        nbNotifications,
        setNbNotifications
    };
};
