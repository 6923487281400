import { getUser } from 'features/auth';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ConnectedUserInState } from 'services/auth/auth.types';
import { IoMdCloudDownload } from 'react-icons/io';
import { useFormik, FormikHelpers } from 'formik';

interface companyInfosFormValues {
    address: string | undefined;
    name: string | undefined;
    siret: string | undefined;
    status: string | undefined;
    vat: string | undefined;
    website: string | undefined;
}

interface accountInfosFormValues {
    firstname: string | undefined;
    lastname: string | undefined;
    email: string | undefined;
    phone: string | undefined;
}

interface resetPasswordFormValues {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

export type TableItemType = {
    id: number;
    title: string;
    date: Date;
    link: string;
};
export type TableItemsType = TableItemType[];

export type TableInvoiceItemType = {
    id: number;
    title: string;
    date: Date;
    link: string;
};
export type TableInvoicesItemType = TableInvoiceItemType[];

export const useSettingsViewVM = () => {
    const user = useSelector(getUser);

    const [updatableUser, setUpdatableUser] = useState<ConnectedUserInState | null>(user);

    const handleUpdatableUserChange = (key: string, val: string | number) => {
        if (!updatableUser) return;

        setUpdatableUser((prevUpdatableUser: ConnectedUserInState | null) =>
            prevUpdatableUser
                ? {
                      ...prevUpdatableUser,
                      [key]: val
                  }
                : null
        );
    };

    const accountInfosForm = useFormik({
        initialValues: {
            firstname: updatableUser?.firstname,
            lastname: updatableUser?.lastname,
            email: updatableUser?.email,
            phone: updatableUser?.phone
        },
        onSubmit: (values: accountInfosFormValues, { setSubmitting }: FormikHelpers<accountInfosFormValues>) => {
            setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }, 500);
        }
    });

    const companyInfosForm = useFormik({
        initialValues: {
            address: updatableUser?.company.address,
            name: updatableUser?.company.name,
            siret: updatableUser?.company.siret,
            status: updatableUser?.company.status,
            vat: updatableUser?.company.vat,
            website: updatableUser?.company.website
        },
        onSubmit: (values: companyInfosFormValues, { setSubmitting }: FormikHelpers<companyInfosFormValues>) => {
            setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }, 500);
        }
    });

    const resetPasswordForm = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
        },
        onSubmit: (values: resetPasswordFormValues, { setSubmitting }: FormikHelpers<resetPasswordFormValues>) => {
            setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }, 500);
        }
    });

    const [invoices] = useState<TableInvoicesItemType>([
        {
            id: 1,
            title: 'Facture Février 2024',
            date: new Date('02/02/2024'),
            link: 'https://www.uniqlo.com/fr/fr/company/pdf/Returns_policy.pdf'
        },
        {
            id: 2,
            title: 'Facture Mars 2024',
            date: new Date('02/03/2024'),
            link: 'https://www.uniqlo.com/fr/fr/company/pdf/Returns_policy.pdf'
        },
        {
            id: 3,
            title: 'Facture Avril 2024',
            date: new Date('02/04/2024'),
            link: 'https://www.uniqlo.com/fr/fr/company/pdf/Returns_policy.pdf'
        },
        {
            id: 4,
            title: 'Facture Mai 2024',
            date: new Date('02/05/2024'),
            link: 'https://www.uniqlo.com/fr/fr/company/pdf/Returns_policy.pdf'
        },
        {
            id: 5,
            title: 'Facture Juin 2024',
            date: new Date('02/06/2024'),
            link: 'https://www.uniqlo.com/fr/fr/company/pdf/Returns_policy.pdf'
        }
    ]);

    const onDownloadButtonClick = (invoiceLink: string) => {
        const link = document.createElement('a');
        link.href = invoiceLink;
        link.download = 'facture.pdf'; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const ActionButtonsRenderer = (props: { data: TableItemType }) => {
        const invoiceLink = props.data.link;

        return (
            <div className="actions">
                <IoMdCloudDownload className="icon" onClick={() => onDownloadButtonClick(invoiceLink)} />
            </div>
        );
    };

    const invoicesTableColumns = [
        { headerName: 'Numéro', field: 'id', maxWidth: 100, minWidth: 100 },
        { headerName: 'Titre', field: 'title' },
        {
            headerName: 'Date',
            field: 'date',
            valueGetter: (params: any) => {
                const date = params.data.date;
                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            }
        },
        { headerName: '', cellRenderer: ActionButtonsRenderer, maxWidth: 50, minWidth: 50 }
    ];

    const tabs = ['compte', 'abonnement'];

    return {
        updatableUser,
        handleUpdatableUserChange,
        accountInfosForm,
        companyInfosForm,
        resetPasswordForm,
        invoices,
        invoicesTableColumns,
        tabs
    };
};
