import { getIsLogged, getUser, logout } from 'features/auth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce';

export const useHeaderVm = () => {
    const dispatch = useDispatch();
    const isLogged = useSelector(getIsLogged);
    const navigate = useNavigate();

    const [search, setSearch] = useState<string>('');
    const [displaySearch, setDisplaySearch] = useState<boolean>(false);
    const [displayMenu, setDisplayMenu] = useState<boolean>(false);
    const [displayNotifications, setDisplayNotifications] = useState<boolean>(false);
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        setDisplaySearch(!!search);
    }, [search]);

    const handleLogout = () => {
        dispatch(logout());
    };

    const goToSettings = () => {
        navigate('/dashboard/settings');
    };

    const goToNotificationsCenter = () => {
        navigate('/dashboard/notifications');
        setDisplayNotifications(false);
    };

    return {
        isLogged,
        handleLogout,
        search,
        setSearch,
        debouncedSearch,
        displayMenu,
        setDisplayMenu,
        displaySearch,
        setDisplaySearch,
        goToSettings,
        displayNotifications,
        setDisplayNotifications,
        goToNotificationsCenter
    };
};
