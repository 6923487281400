import React from 'react';
import './Pin.scss';

export type PinProps = {
    value: number | string;
} & React.ComponentPropsWithRef<'div'>;

export const Pin = React.forwardRef<HTMLDivElement, PinProps>(({ value }, ref) => {
    return (
        <div className="pin" ref={ref}>
            <span>{value}</span>
        </div>
    );
});
