import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

export type usePaginatedContentProps = {
    children: React.ReactNode[];
    pageSize: number;
};

export const usePaginatedContent = ({ children, pageSize }: usePaginatedContentProps) => {
    const [paginatedContent, setPaginatedContent] = useState<React.ReactNode[]>(children);
    const [pageIndex, setPageIndex] = useState<number>(1);

    useEffect(() => {
        setPaginatedContent(_.chunk(children, pageSize));
    }, []);

    return {
        paginatedContent: paginatedContent[pageIndex - 1],
        pageIndex,
        totalPageIndex: paginatedContent.length,
        setPageIndex
    };
};
